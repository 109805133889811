function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { getItemPricing } from './order/group-items-as-related-and-default.logic';
import getSetupFeeFromRelatedItems from './get-setup-fee-for-related-items.logic';
function getRelatedItems({
  itemsToCalculate,
  allOrderItems
}) {
  const relatedItemIds = {};
  const groupedRelatedItems = [];
  itemsToCalculate.forEach(item => {
    if (relatedItemIds[String(item._id)] || !item.asRelatedItemId) {
      return null;
    }
    const relatedToThisItems = itemsToCalculate.filter(innerItem => {
      return innerItem.asRelatedItemId === item.asRelatedItemId;
    });
    if (relatedToThisItems.length > 1) {
      const itemsGroup = relatedToThisItems.map(item => getItemPricing({
        item,
        allOrderItems
      }));
      groupedRelatedItems.push(itemsGroup);
      const ids = relatedToThisItems.reduce((acc, currItem) => {
        acc[String(currItem._id)] = currItem._id;
        return acc;
      }, {});
      _extends(relatedItemIds, ids);
    }
  });
  return {
    relatedItemIds,
    groupedRelatedItems
  };
}
export function getTotalSetupFeeForAllItems({
  itemsToCalculate,
  allOrderItems,
  featureFlags
}) {
  const {
    relatedItemIds,
    groupedRelatedItems
  } = getRelatedItems({
    itemsToCalculate,
    allOrderItems
  });
  const setupFeeFromRelatedItems = groupedRelatedItems.reduce((sum, array) => {
    const maxSetupFee = getSetupFeeFromRelatedItems(array);
    return maxSetupFee + sum;
  }, 0);
  return setupFeeFromRelatedItems + itemsToCalculate.reduce((setupFeeSum, incomingItem) => {
    if (relatedItemIds[String(incomingItem._id)]) {
      return setupFeeSum;
    }
    const item = getItemPricing({
      item: incomingItem,
      allOrderItems,
      featureFlags
    });
    return setupFeeSum + item.setupFee;
  }, 0);
}