import { isCancelledItem } from 'swag-common/utils/order';
import { calculateMaxSetupFeeForRelatedItems } from 'swag-common/business-logic/calculate-setup-fee-value.logic';
import { calculateSetupFeeDeductionDiscount } from 'swag-common/business-logic/order/calculate-setup-fee-deduction-discount.logic';
import { getDataForGettingItemShippingCost, getItemShippingCost } from './order/get-item-shipping-cost.service';
const getRelatedItemsPricing = (relatedItems, {
  withShipping = false
} = {}) => {
  const relatedItemsMaxSetupFee = calculateMaxSetupFeeForRelatedItems({
    orderRelatedItems: relatedItems
  });
  const setupFeeDeductionDiscount = calculateSetupFeeDeductionDiscount(relatedItems);
  const filteredItems = relatedItems.filter(item => !isCancelledItem(item));
  let total = filteredItems.reduce((price, next) => price + next.price, 0);
  if (withShipping) {
    const totalShipping = filteredItems.reduce((shippingPrice, next) => getItemShippingCost(getDataForGettingItemShippingCost(next, filteredItems)) + shippingPrice, 0);
    total += totalShipping;
  }
  const isRelated = relatedItems.length > 1;
  let oldSetupFee = 0;
  if (setupFeeDeductionDiscount) {
    oldSetupFee = setupFeeDeductionDiscount + relatedItemsMaxSetupFee;
  }
  return {
    total,
    isRelated,
    setupFee: relatedItemsMaxSetupFee,
    oldSetupFee
  };
};
export default getRelatedItemsPricing;