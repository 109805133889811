import { getProductBasedProductSettings } from 'swag-common/utils/product-settings/get-product-based-product-settings';
export function transformProductSettingsForFeature(productSettings, product, featureFlags) {
  return productSettings.map(baseSettings => {
    const settings = getProductBasedProductSettings(baseSettings, product);
    if (featureFlags !== null && featureFlags !== void 0 && featureFlags.dynamicQuantityAndLocationsOptionsForDecorationMethods) {
      settings.minQuant = baseSettings.minQuant;
      settings.quantIncrement = baseSettings.quantIncrement;
      settings.maxPrintColors = baseSettings.maxPrintColors;
    }
    return settings;
  });
}