import { DesignUploadMode } from 'swag-common/interfaces';
import { applyMultiplier } from 'swag-common/utils/tenant-price-settings';
import { transformProductSettingsForFeature } from 'swag-common/utils/product-settings/transform-product-settings-for-feature';
import { getDefaultProductSettings } from 'swag-common/utils/items/get-item-selected-product-settings.util';
import { getItemPriceMultiplier } from 'swag-common/business-logic/get-item-price-multiplier.logic';
import isSharedItem from 'swag-common/utils/order/is-item-shared.util';
import { isRelatedItem } from 'swag-common/utils/items/is-item-shared-or-related';
import { getRelatedItems } from 'swag-common/business-logic/order/group-items-as-related-and-default.logic';
import * as ITEM_STATUSES from 'swag-common/constants/item-statuses.constants';
import * as colorsUtils from 'swag-common/utils/colors';
import { Price } from 'swag-common/utils/price';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { getUniqueRelatedIds } from 'swag-common/business-logic/get-unique-related-ids.logic';
import * as Colors from '../utils/colors';
export const getShouldUseLegacyLogic = (item, allOrderItems) => {
  // we write this field to DB after order is placed
  // after that, we don't change the value anymore
  const wasOrderPlaced = allOrderItems.some(item => item.status && ![ITEM_STATUSES.DISPLAY_ALL, ITEM_STATUSES.INITIAL].includes(item.status));

  // for fullDesign we don't have setupFeePerItem but actual setupFee should be included in price
  const isFullDesignProduct = item.prod.designUploadMode === DesignUploadMode.fullDesign;
  return typeof (item === null || item === void 0 ? void 0 : item.setupFeePerItem) !== 'number' && wasOrderPlaced || isFullDesignProduct;
};
export const calculateSetupFeePerItem = ({
  item,
  allOrderItems,
  featureFlags,
  shouldRecalculate = false,
  shouldNotSplitSetupFee: shouldNotSplitSetupFeeParam = false,
  skipTimeCostCalculation = false
}) => {
  const shouldNotSplitSetupFee = shouldNotSplitSetupFeeParam || getShouldUseLegacyLogic(item, allOrderItems);
  const isSampleItem = item.isSample || item.isPrintedSample;
  const multiplier = getItemPriceMultiplier(item);
  if (shouldNotSplitSetupFee) {
    return calculateTotalSetupFee({
      product: item.prod,
      logos: item.logos,
      texts: item.texts,
      isSample: isSampleItem,
      prodTime: item.prodTime,
      curatedBrandStoreProductId: item === null || item === void 0 ? void 0 : item.curatedBrandStoreProductId,
      multiplier,
      featureFlags,
      skipTimeCostCalculation
    });
  }
  if (!shouldRecalculate && typeof (item === null || item === void 0 ? void 0 : item.setupFeePerItem) === 'number') {
    return item.setupFeePerItem;
  }
  const orderRelatedItems = allOrderItems.filter(i => i.asRelatedItemId === item.asRelatedItemId);
  const setupFeeTotal = calculateMaxSetupFeeForRelatedItems({
    orderRelatedItems,
    featureFlags
  });
  const isShared = isSharedItem(item, allOrderItems);
  const isRelated = isRelatedItem(item, allOrderItems, true);
  const isSetupFeeDistributed = isShared || isRelated;
  if (!isSetupFeeDistributed) {
    return setupFeeTotal;
  }
  const setupFeeCoefficient = item.blendedQuantity ? item.quantity / item.blendedQuantity : 1;
  const itemSetupFee = Math.floor(setupFeeTotal * setupFeeCoefficient);
  const hasAnyItemSetupFeeRemainder = orderRelatedItems.some(relatedItem => typeof relatedItem.hasSharedSetupFeeRemainder === 'boolean');
  const itemIndex = orderRelatedItems.findIndex(relatedItem => getAreItemsEqual(item, relatedItem));
  const isLastRelatedItem = orderRelatedItems.length - 1 === itemIndex;
  const shouldHaveRemainder = hasAnyItemSetupFeeRemainder ? item === null || item === void 0 ? void 0 : item.hasSharedSetupFeeRemainder : isLastRelatedItem;
  if (!shouldHaveRemainder) {
    return itemSetupFee;
  }
  let itemSetupFeeWithRemainder = setupFeeTotal;
  orderRelatedItems.forEach(relatedItem => {
    const isSameItem = getAreItemsEqual(item, relatedItem);
    if (isSameItem || !relatedItem.blendedQuantity) {
      return;
    }
    const relatedItemSetupFee = typeof (relatedItem === null || relatedItem === void 0 ? void 0 : relatedItem.setupFee) === 'number' ? relatedItem.setupFee : setupFeeTotal;
    const coefficient = relatedItem.quantity / relatedItem.blendedQuantity;
    const setupFeePerItem = Math.floor(Number(relatedItemSetupFee) * coefficient);
    itemSetupFeeWithRemainder -= setupFeePerItem;
  });
  return Math.ceil(itemSetupFeeWithRemainder);
};
const getAreItemsEqual = (item, relatedItem) => {
  const isClientIdEqual = 'tempClientId' in relatedItem && 'tempClientId' in item && Boolean(item.tempClientId) && (relatedItem === null || relatedItem === void 0 ? void 0 : relatedItem.tempClientId) === (item === null || item === void 0 ? void 0 : item.tempClientId);
  return item._id && relatedItem._id ? item._id === relatedItem._id : isClientIdEqual;
};
export const calculateTotalSetupFee = ({
  product,
  logos = {},
  texts,
  isSample = false,
  prodTime = 0,
  multiplier = 0,
  featureFlags,
  skipTimeCostCalculation = false,
  curatedBrandStoreProductId
}) => {
  var _product$productSetti, _product$productSetti2;
  if (isSample || !product || product.designUploadMode === DesignUploadMode.fullDesign || product.designUploadMode === DesignUploadMode.box || curatedBrandStoreProductId) {
    return 0;
  }
  const productSettings = product.productSettings ? transformProductSettingsForFeature(product.productSettings, product, featureFlags) : getDefaultProductSettings(product, featureFlags);
  const {
    colorIndependentPrice,
    colorIndependentScreenPrice,
    screenPrice,
    addOneColor
  } = productSettings[0];
  const colors = Colors.getUniqueColorsNumberForAllSides(logos, texts);
  let colorsAmount = Object.values(colors).reduce((colorsSum, number) => {
    let amount = number;
    if (addOneColor) {
      amount += 1;
    }
    return amount + colorsSum;
  }, 0);
  if (colorIndependentPrice || colorIndependentScreenPrice) {
    colorsAmount = 1 * Object.keys(logos).length;
  }
  const productionTimeList = ((_product$productSetti = product.productSettings) === null || _product$productSetti === void 0 ? void 0 : _product$productSetti.length) && ((_product$productSetti2 = product.productSettings) === null || _product$productSetti2 === void 0 ? void 0 : _product$productSetti2[0].productionTimeList) || [];
  let timeCost = 1;
  if (!skipTimeCostCalculation) {
    timeCost = productionTimeList[prodTime] ? productionTimeList[prodTime].cost : 0;
  }
  const setupFee = screenPrice * colorsAmount;
  const additionalProductionCost = setupFee * timeCost;
  const total = setupFee + additionalProductionCost;
  const result = applyMultiplier(total, multiplier);
  return Number(result);
};
export const calculateMaxSetupFeeForRelatedItems = ({
  orderRelatedItems,
  featureFlags
}) => {
  const setupFees = orderRelatedItems.map(i => {
    const multiplier = getItemPriceMultiplier(i);
    return calculateTotalSetupFee({
      product: i.prod,
      logos: i.logos,
      texts: i.texts,
      isSample: i.isSample || i.isPrintedSample,
      prodTime: i.prodTime,
      curatedBrandStoreProductId: i === null || i === void 0 ? void 0 : i.curatedBrandStoreProductId,
      multiplier,
      featureFlags
    });
  });
  if (!setupFees.length) {
    return 0;
  }
  return Math.max(...setupFees);
};
export const getShouldItemHaveSharedSetupFeeRemainder = (item, allOrderItems) => {
  const orderRelatedItems = getRelatedItems({
    item,
    items: allOrderItems
  });
  if (orderRelatedItems.length <= 1) {
    return false;
  }
  const itemIndex = orderRelatedItems.findIndex(currentItem => {
    return getAreItemsEqual(currentItem, item);
  });
  const isLastRelatedItem = orderRelatedItems.length - 1 === itemIndex;
  const isSharedSetupFeeRemainderSet = orderRelatedItems.some(item => item.hasSharedSetupFeeRemainder);
  return !isSharedSetupFeeRemainderSet && isLastRelatedItem;
};
export const calculatePricingSetupFeePerItem = ({
  orderItem,
  allOrderItems,
  blendedQuantity,
  screenPrice,
  skipTimeCostCalculation = false,
  shouldNotSplitSetupFee
}) => {
  const shouldUseLegacyLogic = shouldNotSplitSetupFee || getShouldUseLegacyLogic(orderItem, allOrderItems);
  if (shouldUseLegacyLogic) {
    const totalScreenPrice = Object.keys(orderItem.logos || {}).reduce((price, side) => {
      if (!orderItem.logos[side].length) {
        return price;
      }
      const colorsNumber = colorsUtils.getUniqueColorsNumberForSide(orderItem.logos, orderItem.texts, side);
      const sideScreenPrice = Price.getSideScreenPrice({
        orderItem,
        colorsNumber,
        blendedQuantity,
        screenPrice
      });
      return price + sideScreenPrice;
    }, 0);
    return totalScreenPrice;
  }
  return calculateSetupFeePerItem({
    item: orderItem,
    allOrderItems,
    skipTimeCostCalculation
  });
};
export const getSetupFeeForLogoProduct = params => {
  let setupFee = calculateSetupFeePerItem(params);
  if (!!params.item.setupFeeDeductionDiscount && !!setupFee) {
    const calculatedSetupFee = setupFee - params.item.setupFeeDeductionDiscount;
    setupFee = calculatedSetupFee >= 0 ? calculatedSetupFee : 0;
  }
  return setupFee;
};
export const getSetupFeeForBoxProduct = params => {
  const {
    item,
    allOrderItems
  } = params;
  const items = (allOrderItems || []).filter(cartItem => {
    var _cartItem$prod;
    return ((_cartItem$prod = cartItem.prod) === null || _cartItem$prod === void 0 ? void 0 : _cartItem$prod.designUploadMode) !== DesignUploadMode.box && cartItem.boxItemsId === item.boxItemsId;
  });
  if (!items || !items.length || isBox(item)) {
    return 0;
  }
  let setupFee = items.reduce((all, currentItem) => {
    return all + calculateSetupFeePerItem(params);
  }, 0);
  if (!!item.setupFeeDeductionDiscount && !!setupFee) {
    setupFee -= item.setupFeeDeductionDiscount;
  }
  if (setupFee < 0) {
    setupFee = 0;
  }
  return setupFee;
};
export const calculateSetupFeeForSwagItem = params => {
  if (params.item.prod.designUploadMode === DesignUploadMode.box) {
    return getSetupFeeForBoxProduct(params);
  }
  return getSetupFeeForLogoProduct(params);
};
export const getMaxSetupFee = items => {
  const relatedItemIds = getUniqueRelatedIds(items);
  const maxSetupFees = {};
  relatedItemIds.forEach(id => {
    maxSetupFees[id] = Math.max.apply(null, items.filter(i => i.asRelatedItemId === id).map(i => {
      const multiplier = getItemPriceMultiplier(i);
      return calculateTotalSetupFee({
        product: i.prod,
        logos: i.logos,
        texts: i.texts,
        multiplier,
        curatedBrandStoreProductId: i === null || i === void 0 ? void 0 : i.curatedBrandStoreProductId
      });
    }));
  });
  return maxSetupFees;
};